<template>
    <div class="page">
        <div class="doc-page">
            <div class="left" ref="left">
                <div :class="['directory-list', isScroll? 'is-fiexd': null]" :style="{'width': fiexdWidth}">
                    <div class="theme">Catalog</div>
                    <div class="items">
                        <catalog v-if="directory.length" :arr="directory" :index="activeTIndex" :root="this"></catalog>
                    </div>
                </div>
            </div>
            <div class="doc" v-loading="isLoad">
                <div class="title"><p>{{ title }}<i class="el-icon-download t-btn" @click="getMaterialById"></i></p></div>
                <div class="paper">
                    <div ref="html" v-html="doc" @click="btnClick($event)"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {getMaterialHtml, getMaterialById} from "@/api/documentation"
import axios from "axios"
import xml2js from 'xml2js'
import catalog from './htmldocCatalog'
export default {
    name: 'WebsiteVueHtmldoc',

    components: {
        catalog
    },

    data() {
        return {
            materialId: null,
            directory: [],
            directoryList: {},
            title: '',
            src: '',
            doc: null,
            selectedIndex: 0,
            isScroll: false,
            activeTIndex: '',
            isLoad: false
        };
    },

    computed: {
        fiexdWidth() {
            if (this.isScroll) {
                return this.$refs.left.clientWidth + 'px'
            } else {
                return '100%'
            }
        }
    },

    mounted() {
        this.materialId = this.$route.params.id
        if (this.materialId) {
            this.getData()
        }
        window.addEventListener('scroll', () => this.contentScroll(), false);
    },

    methods: {
        async getMaterialById() {
           if (!this.materialId) {
            return false;
           }
           const {data: {data, isSuccess}} = await getMaterialById({
                id: this.materialId
           })
           if (isSuccess) {
            // console.log(data)
            window.open(`https://resources.holowits.com/${data.viewUrl}`)
           }
        },
        btnClick(e) {
            // console.log(e)
            let target = e.target.innerText.trim();
            let {dataset: {url}} = e.target;
            if (target in this.directoryList) {
                this.activeTreeIndex({
                    label: target,
                    url: url
                })
            }
        },
        async activeTreeIndex(value) {
            this.isLoad = true;
            this.activeTIndex = value.url;
            await this.getPaper(value.url)
            this.isLoad = false;
        },
        contentScroll(e) {
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            this.isScroll = scrollTop >= 100;
        },
        async getData() {
            const {data: {data, isSuccess}} = await getMaterialHtml({
                materialId: this.materialId
            })
            if (isSuccess) {
                this.title = data[0].title
                await this.xmlToJson(data[0].src)
                this.activeTreeIndex(this.directory[0])
            }
        },
        async downloadxml(src) {
            let rsp = '';
            let src1 = src.replace('https://resources.holowits.com.sg/', '/resource/');
            rsp = await axios.get(src1);
            // if (process.env.NODE_ENV === 'development') {
            //     let src1 = src.replace('https://resources.holowits.com.sg/', '/resource');
            //     rsp = await axios.get(src1);
            // } else {
            //     rsp = await axios.get(src)
            // }
            
            return  new Promise((resolve, reject) => {
                new xml2js.Parser().parseStringPromise(rsp.data).then((result) => {
                    resolve(result);
                }).catch((err) => {
                    reject(err)
                });
            }) 
        },
        async xmlToJson(src) {
            const json = await this.downloadxml(src)
            let arr = [];
            let sumObj = {sum: 0};

            this.digui(json, arr, 0, sumObj)

            let result = arr[0]['children'][0]['children'][0]

            this.a1(result['children'])

            this.a2(result['children'])

            this.a3(result['children'])

            this.directory = result['children']
        },

        a3(obj) {
            for (let item of obj) {
                this.directoryList[item.label] = item.url;
                delete item.deep;
                if (item.children.length == 0) {
                    delete item.children;
                } else {
                    this.a3(item.children);
                }
            }
        },

        a2(obj) {
            for (let item of obj) {
                if (item.deep % 2 === 0) {
                    if (item.children.length) {
                        item.children = item.children[0].children
                    }
                }
                this.a2(item['children'])
            }
        },

        a1(obj) {
            for (let item of obj) {
                if (item.deep % 2 === 0) {
                    item.label = item['children'][0]['txt']
                    item.url   = item['children'][0]['url']
                    item['children'].splice(0, 1)
                }
                this.a1(item['children'])
            }
        },

        digui(obj, param, index, sumObj) {
            let param1 = {
                label: '',
                children: [],
                deep: index + 1
            };
            for (let key in obj) {
                if (typeof obj[key] == 'object') {
                    param1['label'] = key;
                    this.digui(obj[key], param1['children'], param1['deep'], sumObj)
                }
                if (typeof obj[key] == 'string') {
                    delete param1['children']
                    delete param1['label']
                    if (key == 'txt') {
                        param1['txt'] = obj[key]
                    }
                    if (key == 'url') {
                        param1['url'] = obj[key]
                    }
                }
            }
            if (sumObj.sum < param1.deep) {
                sumObj.sum = param1.deep
            }
            param.push(param1)
        },

        async getPaper(src) {
            // this.selectedIndex = index
            let rsp = '';
            let src1 = src.replace('https://resources.holowits.com.sg/', '/resource/');
            rsp = await axios.get(src1);
            // if (process.env.NODE_ENV === 'development') {
            //     let src1 = src.replace('https://resources.holowits.com.sg/', '/resource');
            //     rsp = await axios.get(src1);
            // } else {
            //     rsp = await axios.get(src)
            // }
            if (rsp.data) {
                let parser = new DOMParser();
                let doc = parser.parseFromString(rsp.data, 'text/html')
                // doc.getElementById('tocd0e37').remove()
                // 替换文档内的 a标签
                let a = doc.getElementsByTagName('a')
                for (let i = 0; i < a.length; i++) {
                    a[i].setAttribute('data-url', this.directoryList[a[i].innerText.trim()]);
                    a[i]. removeAttribute('href');
                    a[i].style.cursor = 'pointer';
                }


                let link = doc.getElementsByTagName('link')[0]
                let cssSrc = link.getAttribute('href')
                let config = {
                    headers: {
                        Accept: `text/css,*/*;q=0.1`
                    }
                }
                const {data} = await axios.get(cssSrc.replace('https://resources.holowits.com.sg/', '/resource/'), config);
                // const {data} = process.env.NODE_ENV === 'development'? await axios.get(cssSrc.replace('https://resources.holowits.com.sg/', '/resource/')) : await axios.get(cssSrc);
                let head = doc.getElementsByTagName('head')[0];
                let style = doc.createElement('style')
                style.setAttribute('type', 'text/css');
                style.innerHTML = data;
                head.appendChild(style);
                let serializer = new XMLSerializer();
                let str = serializer.serializeToString(doc)
                this.doc = str
            }
        }
    },
};
</script>

<style lang="scss" scoped>

.page {
    min-height: 100vh;
    background-color: #F9F9F9;
    width: 100%;
    margin: auto;
}

.title {
    font-size: 40px;
    margin-bottom: 15px;
    .t-btn {
        font-size: 32px;
        cursor: pointer;
        margin-left: 40px;
        padding: 5px;
        // color: #d2333c;
        color: #ffffff;
        border: 2px solid #d2333c;
        background-color: #d2333c;
        border-radius: 12px;
    }
}
.theme {
    font-size: 22px;
    font-weight: 600;
}
.left {
    width: 20%;
    // height: 100%;
}
.directory-list {
    background-color: #ffffff;
    padding: 10px;
    width: 100%;
    
    .items {
        max-height: 600px;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 2px;
            background-color: #ffffff;
        }
        &::-webkit-scrollbar-thumb {
            background-color: #d2333b83;
            
        }
        .item {
            font-size: 16px;
            height: 45px;
            line-height: 45px;
            border-bottom: 1px solid #ccc;
            &:hover {
                cursor: pointer;
                color: #d2333c;
            }
        }
    }
    
    .active {
        cursor: pointer;
        color: #d2333c;
    }
}
.doc {
    background-color: #ffffff;
    width: calc(100% - 20% - 20px);
    // float: left;
    margin-left: calc(20px);
    padding: 10px;
}
.doc-page {
    // overflow: hidden;
    width: 1600px;
    min-height: 100vh;
    margin: auto;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: flex-start;
}
.is-fiexd {
    position: fixed;
    top: 85px;
}
.paper {
    margin-top: 20px;
}
iframe {
    width: 100%;
    min-height: 2000px;
}
</style>