<template>
  <div class="tree">
    <div v-for="item in arr" :key="item.label">
        <div class="tree-label" :class="{'active': activeIndex === item.url}" @click="activeNode(item)"><p>{{ item.label }}</p></div>
        <div v-if="item.children" class="tree-chid">
            <doc-catelog :arr="item.children" :index="activeIndex" :root="root"></doc-catelog>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'DocCatelog',
    props: {
        arr: {
            type: Array,
            required: true,
            default: []
        },
        index: {
            type: String,
        },
        root: {
            type: Object,
            require: true,
            default: this
        }
    },
    data() {
        return {
        }
    },
    computed: {
        activeIndex() {
            return this.index;
        }
    },
    methods: {
        activeNode(item) {
            this.root.activeTreeIndex(item)
        }
    }
}
</script>

<style lang="scss" scoped>
.active {
    background-color: #d2333c;
    color: #ffffff;
}
.tree-label {
    // height: 30px;
    line-height: 30px;
    padding-left: 10px;
    margin-right: 10px;
    cursor: pointer;
    transition: all 100ms;
    &:hover {
        background-color: #d2333c;
        color: #ffffff;
    }
    p {
        word-break: break-all;
    }
}
.tree-chid {
    margin-left: 30px;
}
</style>