import axiosApi from '@/api/axiosApi';

//获取筛选项列表
export const getFilter = (data) => axiosApi({
  method: 'POST',
  url: '/intserv/filter/list',
  data,
});
//查询下载量TOP10资料
export const getHotList = (data) => axiosApi({
  method: 'POST',
  url: '/intserv/material/hotTop10',
  data,
});
//多条件分页查询资料
export const getList = (data) => axiosApi({
  method: 'POST',
  url: '/intserv/material/pageWith',
  data,
});
//技术支持页面查询资料
export const getListSupport = (data) => axiosApi({
  method: 'POST',
  url: '/intserv/material/supportPageWith',
  data,
});

//根据文件id打包下载
export const download = (data,meta) => axiosApi({
  method: 'POST',
  url: '/file/file/download',
  data,
  meta:meta,
  responseType: "blob",
  hasHeader: true
});
//记录下载次数
export const addDownloadTimes = (data) => axiosApi({
  method: 'POST',
  url: '/intserv/material/addDownloadTimes',
  data,
});

// 获取所有的查询关键字
export const getMeterialTypes = () => axiosApi({
  method: 'GET',
  url: '/intserv/material/getMeterialTypes'
})

// 根据文档id获取对应html文档
export const getMaterialHtml = (data) => axiosApi({
  method: 'POST',
  url: '/intserv/material/getMaterialHtml',
  data
})
// 根据文档id获取对应文档
export const getMaterialById = (data) => axiosApi({
  method: 'POST',
  url: '/intserv/material/getMaterialById',
  data
})